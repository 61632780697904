import React from 'react'
import { Link } from 'react-router-dom';
import { FaEnvelope, FaPhoneAlt, FaLocationArrow } from "react-icons/fa";

const Footer = () => {
    return (
        <>
            <footer className='pt-5 pb-3 bg-center rounded-5' style={{ "backgroundColor": "#0A0A0A" }}>
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-lg-3 col-md-6 my-3">
                            <h3 className='b-color mb-0 fw-bold'>interiorVerse</h3>
                            <h5 className='text-white mb-4'>by interioXR</h5>
                            <h6 className='text-white jt'>Regenerating scattered realities through cutting-edge solutions connecting existing 2D business processes with 3D interfaces & welcoming businesses to a new tomorrow.</h6>
                        </div>
                        <div className="col-lg-3 col-md-6 my-3">
                            <h5 className='text-white mb-4 fw-bold'>Contact</h5>
                            <ul class="list-group">
                                <li class="list-group-item border-0 bg-transparent text-white pb-1"><FaLocationArrow/> DLF Phase IV, Gurugram</li>
                                <Link onClick={() => window.location = 'mailto: info@interioXr.com'}><li class="list-group-item border-0 bg-transparent text-white pb-1"><FaEnvelope/> info@interioxr.com</li></Link>
                                <Link onClick={() => window.location = 'tel: +91 9560013324'} ><li class="list-group-item border-0 bg-transparent text-white"><FaPhoneAlt/> +91 9560013324</li></Link>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6 my-3">
                            <h5 className='text-white mb-4 fw-bold'>Quick Menu</h5>
                            <ul class="list-group">
                                <Link to='https://www.interioxr.com/projects'><li class="list-group-item border-0 bg-transparent text-white pb-1">Projects</li></Link>
                                <Link to=''><li class="list-group-item border-0 bg-transparent text-white pb-1">About</li></Link>
                                <Link to=''><li class="list-group-item border-0 bg-transparent text-white pb-1">Careers</li></Link>
                                <Link to=''><li class="list-group-item border-0 bg-transparent text-white">Terms Of Service</li></Link>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6 my-3">
                            <h5 className='text-white mb-4 fw-bold'>SOCIAL</h5>
                            <ul class="list-group">
                                <Link to=''><li class="list-group-item border-0 bg-transparent text-white pb-1">Facebook</li></Link>
                                <Link to=''><li class="list-group-item border-0 bg-transparent text-white pb-1">Instagram</li></Link>
                                <Link to=''><li class="list-group-item border-0 bg-transparent text-white pb-1">Twitter</li></Link>
                                <Link to=''><li class="list-group-item border-0 bg-transparent text-white">YouTube</li></Link>
                            </ul>
                        </div>
                    </div>
                    <div className="company-name text-center">
                        <h1 className='text-white'>interioXr Technologies</h1>
                        <h6 className='text-white'>© 2023 by interioXR.</h6>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer