import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Head from './Head';
import Home from './Home';
import Contact from './Contact';
import Footer from './Footer';

function App() {
  return (
    <>
      <BrowserRouter>
        <Head />
        <Routes>
          <Route>
            <Route path='/' element={<Home />} />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/*' element={<Navigate to='/' />}></Route>
          </Route>
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
