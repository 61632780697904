import React from 'react';
import logo from './images/Logo.png';
import { FaLink, FaWhatsapp, FaInstagram, FaFacebook, FaLinkedin, FaTwitter, FaDiscord } from "react-icons/fa";
import {Link} from 'react-router-dom';

const Home = () => {
    return (
        <>
            <section className='sec-1 rounded-5 mb-5'>
                <div className="container position-relative w-100 text-center" style={{ "top": "90%" }} >
                    <img src={logo} alt="" className='mb-3' style={{ "width": "15%" }} />
                    <h3 className='text-white fw-bold'>INDIA</h3>
                </div>
            </section >

            <section className='sec-2 mb-5' style={{ "marginTop": "70px" }} id='what'>
                <div className="container bord p-3 bg-center rounded-5">
                    <h5 className='jt text-white mb-4'>InteriorVerse is a marquee project of InterioXr Technologies
                        (iXr), present in India, Australia, and the UK. Through InteriorVerse, iXr is partnering
                        up with thousands of Interior Designers & Architects in different countries to bring their
                        designs onto extended reality - AR, VR, and Mixed Reality. </h5>

                    <h5 className='jt text-white mb-4'>InterioXr has already developed applications for the Meta Quest,
                        Android, and iOS platforms, and is now building the same for the upcoming Vision Pro
                        platform as well.</h5>

                    <h5 className='jt text-white'>Through InteriorVerse, people can step into any interior or spatial design that they like,
                        and can experience an immersive virtual reality walkthrough of any space that they can
                        imagine.</h5>
                </div>
            </section>

            <section className='sec-3 my-5' id='contact'>
                <div className="container bord p-3 bg-center rounded-5">
                    <h4 className='jt text-white mb-4 text-center'><u>Keen On Partnering Up</u></h4>
                    <div className="row align-items-center">
                        <div className="col-md-5 mb-3">
                            <h5 className='text-white text-center'>Start a Conversation with Us</h5>
                        </div>
                        <div className="col-md-7">
                            <div className="formGroup news m-auto">
                                <input type="email" placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                <button type="button" value="Subscribe" className="SubscribeBtn">Sign Up! <FaLink /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Link to=''><button className='btn face fs-3 text-primary pb-3'><FaFacebook/></button></Link>
            <Link to='https://api.whatsapp.com/send/?phone=919560013324&text&type=phone_number&app_absent=0' target='_blank'><button className='btn whats fs-4 text-success pb-3'><FaWhatsapp/></button></Link>
            <Link to=''><button className='btn link fs-3 text-primary pb-3'><FaLinkedin/></button></Link>
            <Link to='https://www.instagram.com/interioxr/' target='_blank'><button className='btn insta fs-3 text-danger pb-3'><FaInstagram/></button></Link>
            <Link to=''><button className='btn twit fs-3 text-primary pb-3'><FaTwitter/></button></Link>
            <Link to='https://discord.com/invite/JJNZr6Hkdh' target='_blank'><button className='btn discord fs-3 text-white pb-3'><FaDiscord/></button></Link>
        </>
    )
}

export default Home;