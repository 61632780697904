import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Link, NavLink } from 'react-router-dom'
import { useRef, useEffect } from "react";
import { FaBars, FaTimes, FaLink, FaHandHoldingHeart, FaHome } from "react-icons/fa";
import { RiContactsFill } from "react-icons/ri";


const Head = () => {

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    const isSticky = (e) => {
        const header = document.querySelector('.header-section');
        const scrollTop = window.scrollY;
        scrollTop >= 100 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };

    const navRef = useRef();

    const showNavbar = () => {
        navRef.current.classList.toggle(
            "responsive_nav"
        );
    };

    return (
        <>
            <header className='header-section position-fixed w-100'>
                <nav ref={navRef}>
                    <NavLink to='/' className='rounded-5'><FaHome/> HOME</NavLink>
                    <a href="#what" className='rounded-5'><FaHandHoldingHeart /> WHAT WE DO</a>
                    <Link to="https://www.interioxr.com/" target='_blank' className='rounded-5'><FaLink /> VISIT OUR WEBSITE</Link>
                    <NavLink to='/contact' className='rounded-5'><RiContactsFill /> CONTACT</NavLink>
                    <button
                        className="nav-btn nav-close-btn"
                        onClick={showNavbar}>
                        <FaTimes />
                    </button>
                </nav>
                <button
                    className="nav-btn"
                    onClick={showNavbar}>
                    <FaBars />
                </button>
            </header>
        </>
    )
}

export default Head;